import React, { Component } from "react"
import SEO from "../components/SEO"
import Post from "../components/Post"
import SiteLayout from "../components/layouts/SiteLayout"
import Container from "../components/layouts/container/Container"
import ViewportCentered from "../components/layouts/ViewportCentered"
import SeoImage from "../images/social-cover.jpg"
import { getPost, getRandomNumberForPost } from "../services/postService"

export default class RandomPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      randomNumber: getRandomNumberForPost(),
    }
  }

  handleRandomClick() {
    this.setState({ randomNumber: getRandomNumberForPost() })
  }

  render() {
    return (
      <SiteLayout onClick={this.handleRandomClick}>
        <SEO
          title={getPost(this.state.randomNumber).title}
          description="Random Advice of the day"
          image={{ src: `${SeoImage}`, width: 1200, height: 627 }}/>
        <Container>
          <ViewportCentered>
            <Post post={getPost(this.state.randomNumber)}/>
          </ViewportCentered>
        </Container>
      </SiteLayout>
    )
  }
}
